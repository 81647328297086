.home_toplevel
    height: 100vh

.home
    text-align: center
    padding-top: 100px

.img
    width: 300px
    @media screen and (min-width: 1921px)
        width: 800px
    @media screen and (max-width: 1920px)
        width: 500px
    @media screen and (max-width: 1068px)
        width: 400px
    @media screen and (max-width: 700px)
        width: 300px
    

.txt
    margin-top: 30px

.home_buttons_toplevel
    margin-top: 30px

.home_buttons
    transition: 500ms
    cursor: pointer
    padding: 10px 15px
    border-radius: 5px
    //background: rgb(200, 200, 240)
    background: rgb(255, 200, 200)
    margin-left: 100px
    &:first-child
        margin-left: 0
    &:hover
        background: rgb(150, 150, 200)
@import url('https://fonts.googleapis.com/css2?family=Cute+Font&display=swap')

body
  margin: 0
  font-family: "Cute Font", 'Helvetica Neue', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace


a
  text-decoration: none
  display: inline-block
  padding: 0
  margin: 0
  color: black

.f1
  @media screen and (min-width: 1921px)
    font-size: 100px
  @media screen and (max-width: 1920px)
    font-size: 50px
  @media screen and (max-width: 1068px)
    font-size: 35px
  @media screen and (max-width: 700px)
    font-size: 30px

.f2
  @media screen and (min-width: 1921px)
    font-size: 50px
  @media screen and (max-width: 1920px)
    font-size: 35px
  @media screen and (max-width: 1068px)
    font-size: 25px
  @media screen and (max-width: 700px)
    font-size: 20px

.f3
  @media screen and (min-width: 1921px)
    font-size: 120px
  @media screen and (max-width: 1920px)
    font-size: 60px
  @media screen and (max-width: 1068px)
    font-size: 60px
  @media screen and (max-width: 700px)
    font-size: 50px
